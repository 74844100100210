<template>
  <div>
    <ManageKnowledgeLibraries />
  </div>
</template>
<script>
import ManageKnowledgeLibraries from "@/components/ManageKnowledgeLibraries/ManageKnowledgeLibraries";
export default {
  components: {
    ManageKnowledgeLibraries,
  },
  created() {},
};
</script>
